.tweetContainer div {
  display: flex;
  justify-content: center;
  
}
.gradient {
  background-image: linear-gradient(-225deg, #112B3C 0%, #5a2593 100%);
}
.buttonGold,
.gradient2 {
  background-color: #f39f86;
  background-image: linear-gradient(315deg, #6dc4ff 0%, #acdeff 74%);
}

.gradient-text {
  background: -webkit-linear-gradient(#eee, #333);
 
}
